import {OpenIdConfiguration} from 'angular-auth-oidc-client/lib/config/openid-configuration';
import {environment} from '../environments/environment';
import {apiVersion} from './api-version.constant';
import {LocalStorageKeys} from './shared/constants/local-storage-keys.constant';
import {JSONUtils} from './shared/services/json-utils.service';

export const LuminaAuthConfigId = 'luminaBaseSystem';

const AuthConfig: OpenIdConfiguration = {
  configId: 'main',
  authority: '/auth/realms/avelon',
  triggerAuthorizationResultEvent: true,
  redirectUrl: location.origin + '/',
  postLogoutRedirectUri: location.origin + '/',
  clientId: 'avelon-web-front-end',
  scope: 'openid offline_access',
  responseType: 'code',
  silentRenew: true,
  // logLevel: 1, // used for debug
  ignoreNonceAfterRefresh: true,
  issValidationOff: !environment.production,
  useRefreshToken: true,
  autoUserInfo: false,
  renewTimeBeforeTokenExpiresInSeconds: 60,
  ngswBypass: true,
  secureRoutes: [apiVersion, 'graphql/', 'auth/']
};
const configs = [AuthConfig];

if (localStorage.getItem(LocalStorageKeys.Lumina.baseSystemUrl)) {
  const luminaAuthConfig = JSONUtils.clone(AuthConfig);
  luminaAuthConfig.configId = LuminaAuthConfigId;
  luminaAuthConfig.authority = localStorage.getItem(LocalStorageKeys.Lumina.baseSystemUrl) + AuthConfig.authority;
  luminaAuthConfig.redirectUrl = location.origin + location.pathname;
  luminaAuthConfig.postLogoutRedirectUri = location.origin + location.pathname;
  configs.push(luminaAuthConfig);
}
export const AuthConfigurations = configs;
